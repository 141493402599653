.footer {
  display: block;
  text-align: center;
  height: 30px;
  border-top: 2px solid #141414;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 95%;
  margin: 0 auto;
 /* Workaround for iPhone 6/7/8 & Galaxy S5*/
 /* Workaround for iPhone 5/SE*/
}
@media screen and (max-height: 500px) {
  .footer {
    position: absolute;
 }
}
@media screen and (max-height: 700px) and (max-width: 400px) {
  .footer {
    padding-top: 10px;
 }
}
@media screen and (max-height: 600px) and (max-width: 400px) {
  .footer {
    padding-top: 40px;
 }
}
.footer__container {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  bottom: 25px;
}
.footer__start, .footer__center, .footer__end {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer__text {
  color: #fff;
  display: inline;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
  font-weight: lighter;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .footer__text {
    font-size: 15px;
 }
}
.footer__text--1 {
  margin-bottom: 5px;
}
.footer__text--2 {
  align-items: center;
}
.footer__text--3 {
  color: grey;
  text-decoration: underline;
}
.footer__link {
  text-decoration: underline;
}
.footer__logo-tmdb {
  width: 90px;
  cursor: pointer;
  margin-top: 30px;
}
.footer__logo-github {
  width: 50px;
  cursor: pointer;
  margin-top: 20px;
}
.footer__icon-heart-o {
  margin-left: 5px;
  margin-bottom: 0px;
  text-shadow: -0.1vw 0vw 0.25vw #fbb4ff, -0.2vw 0vw 0.25vw #fbb4ff, -0.4vw 0vw 0.25vw #fbb4ff, -0.1vw 0vw 1vw #e82ff5, -0.2vw 0vw 0.8vw #e82ff5, -0.4vw 0vw 0.8vw #e82ff5, 0.2vw 0vw 0.5vw #e82ff5, -0.1vw 0vw 10vw #cb16fa, -0.2vw 0vw 10vw #cb16fa, -0.4vw 0vw 10vw #cb16fa;
}
.footer__icon-heart-off {
  animation: heart 0.1s ease infinite;
  -moz-animation: heart 0.1s ease infinite;
 /* Firefox */
  -webkit-animation: heart 0.1s ease infinite;
 /* Safari and Chrome */
  -o-animation: heart 0.1s ease infinite;
 /* Opera */
}
.footer .react {
  display: inline-block;
}
@keyframes heart {
 /* Enable hardware acceleration to fix laggy transitions */
  0%, 100% {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    color: #fbb4ff;
    text-shadow: -0.1vw 0vw 0.25vw #fbb4ff, -0.2vw 0vw 0.25vw #fbb4ff, -0.4vw 0vw 0.25vw #fbb4ff, -0.1vw 0vw 1vw #e82ff5, -0.2vw 0vw 0.8vw #e82ff5, -0.4vw 0vw 0.8vw #e82ff5, 0.2vw 0vw 0.5vw #e82ff5, -0.1vw 0vw 10vw #cb16fa, -0.2vw 0vw 10vw #cb16fa, -0.4vw 0vw 10vw #cb16fa;
 }
  50% {
   /* Enable hardware acceleration to fix laggy transitions */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    color: #8f5493;
    text-shadow: -0.1vw 0vw 0.25vw #8f5493, -0.2vw 0vw 0.25vw #8f5493, -0.4vw 0vw 0.25vw #8f5493, -0.1vw 0vw 0.8vw #59125e, -0.2vw 0vw 0.8vw #59125e, -0.4vw 0vw 0.8vw #59125e, 0.2vw 0vw 0.5vw #59125e, -0.1vw 0vw 3vw #42114e, -0.2vw 0vw 3vw #42114e, -0.4vw 0vw 3vw #42114e;
 }
}
@keyframes text-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  2% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px rgba(242, 22, 22, 1);
 }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
 }
}
