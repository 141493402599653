@media screen and (max-width: 800px) {
  .forgot {
    overflow-x: hidden;
 }
}
.forgot__bg {
  min-height: 100%;
  min-width: 1024px;
  filter: brightness(29%);
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}
@media screen and (max-width: 800px) {
  .forgot__bg {
    display: none;
 }
}
.forgot__container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .forgot__container {
    width: 100%;
 }
}
.forgot__shadow {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  margin-top: 10%;
  height: auto;
  padding: 40px;
  width: 390px;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 800px) {
  .forgot__shadow {
    margin-top: 150px;
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding: 40px 10px;
    margin-bottom: 20px;
 }
}
.forgot__title {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .forgot__title {
    text-align: center;
    margin-left: 0px;
 }
}
.forgot__btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.forgot__btn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  text-align: center;
  width: 68%;
}
.forgot__option {
  width: 100%;
  display: flex;
  justify-content: center;
}
.forgot__option--newuser {
  color: #737373;
  text-align: center;
  margin-right: 5px;
}
.forgot__option--link {
  color: white;
  text-decoration: none;
}
.forgot__option--link:hover {
  text-decoration: underline;
}
.forgot__error-container {
  text-align: center;
  margin-bottom: 15px;
}
.forgot__error {
  color: orange;
}
.forgot__reviewcode {
  color: lightgray;
  margin-bottom: 15px;
  display: inline-block;
}
