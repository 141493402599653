@media screen and (max-width: 800px) {
  .signin {
    overflow-x: hidden;
 }
}
.signin__bg {
  min-height: 100%;
  min-width: 1024px;
  filter: brightness(44%);
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}
@media screen and (max-width: 800px) {
  .signin__bg {
    display: none;
 }
}
.signin__container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .signin__container {
    display: block;
    width: 100%;
 }
}
.signin__shadow {
  background-image: linear-gradient(#000, rgba(0, 0, 0, 0.8));
  margin-top: 85px;
  height: auto;
  padding: 40px;
  width: 390px;
  position: relative;
  top: 0;
}
@media screen and (max-width: 800px) {
  .signin__shadow {
    margin-top: 0px;
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 460px;
    margin-bottom: 20px;
    background-image: none;
 }
}
.signin__title {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .signin__title {
    text-align: center;
    margin-left: 0px;
 }
}
.signin__btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin__btn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  text-align: center;
  width: 70%;
}
@media screen and (max-width: 800px) {
  .signin__btn {
    width: 90%;
 }
}
.signin__google-icon {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.signin__option {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin__option--newuser {
  color: #737373;
  text-align: center;
  margin-right: 5px;
}
.signin__option--link {
  color: white;
  text-decoration: none;
}
.signin__option--link:hover {
  text-decoration: underline;
}
.signin__error-container {
  text-align: center;
}
.signin__error {
  color: orange;
}
.signin__logos {
  z-index: 99;
  margin-top: 240px;
  width: 50%;
}
@media screen and (max-width: 800px) {
  .signin__logos {
    width: 100%;
    margin: 40px 0px 10px 0px;
    display: block;
    text-align: center;
 }
}
.signin__logo-asocar {
  width: 90%;
}
@media screen and (max-width: 800px) {
  .signin__logo-asocar {
    width: 220px;
 }
}
.signin__session-expired {
  background-color: #d22027;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}
