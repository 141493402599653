.spinner-overlay {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.spinner-container {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 50px;
  height: 50px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 99999;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
 }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
 }
}
